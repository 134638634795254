import React from 'react'

class NotFound extends React.Component {

  title = '404'

  render() {
    return (
      <div className="notfound">

        404

      </div>
    )
  }
}

export default NotFound
